/*@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,700&display=swap");*/
@import "font.css";


*:not(i) {
  font-family: "Poppins", sans-serif !important;
  font-size: 15px;
}

h1{
  font-weight: bold !important;
}

.MuiDialog-paperWidthSm-55 {
  max-width: 1396px !important;
}
.MuiDialog-paperWidthSm-46 {
  max-width: 1396px !important;
}
.MuiDialog-paperWidthSm-91{
  max-width: 1300px !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a, a:-webkit-any-link {
  text-decoration: none !important;
}

.text-center {
  text-align: center;
}


.rmwc-data-table__cell--align-end {
  text-align: right;
}

.input-range__label--max .input-range__label-container {
  left: 0;
}

div[role="dialog"] > div > div {
  max-width: 1200px !important;
}

.input-range__label-container {
  left: 0;
}

/*
@media only screen and (max-width: 600px) {



    div.MegaMenu-megaMenuContainer-0-1-35{
      width: 800px;
      color: #fff;
      height: 100%;
      text-align: center;
      background-color: #111B34;
    }

    html.wf-roboto-n3-active.wf-roboto-n5-active.wf-roboto-n7-active.wf-materialicons-n4-active.wf-active{
      width: 828px;
      height: max-content;
    }

    div.MegaMenu-megaMenu-0-1-36.container{
      height: 100%;
      width: 800px;
      background-color: #111B34;
      justify-content: flex-start;
      box-sizing: content-box;

    }
    html {
      display: inline-block;
      width: 800px;
    }

    :root{
      --mdc-layout-grid-margin-phone: 0px;
      --mdc-layout-grid-gutter-phone: 0px;
      --mdc-layout-grid-column-width-phone: 0px;
    }

    .MegaMenu-megaMenu-0-1-36 h4{
      background-color: #111B34;
    }

    .MegaMenu-megaMenuItem-0-1-38{
      background-color: #111B34;
    }

}
*/

#mobile, #mobile_add_cart, #prezzo_mobile {
  display: none;
}


.row {
  max-width: 100%;
}

#farmaci-banco {
  background: url('/images/home-category/farmaci-banco.jpg');
}
#cosmesi-bellezza {
  background: url('/images/home-category/cosmesi-bellezza.jpg');
}
#mamma-bambino {
  background: url('/images/home-category/mamma-bambino.jpg');
}
#fitoterapia {
  background: url('/images/home-category/fitoterapia.jpg');
}
#integratori {
  background: url('/images/home-category/integratori-benessere.jpg');
}
#animali {
  background: url('/images/home-category/animali.jpg');
}
#dispositivi-medici {
  background: url('/images/home-category/dispositivi-medici.jpg');
}
#igiene-cura {
  background: url('/images/home-category/igiene-cura.jpg');
}
.category-home {
  background-size: cover !important;
  margin-left: 11px;
  margin-right: 11px;
  -webkit-flex-basis: 33%;
  flex-basis: 33%;
  max-width: 31%;
  padding: 0 !important;
}

h1 {
  font-size: 36px;
}
a.link-category-home {
  width: 100% !important;
  height: 100% !important;
  display: block;
}
.newletterGrid {
  background-color: #57BEBF;
  margin-left: 0px;
  margin-right: 0px;
  width: 100% !important;
  padding: 0 !important;
  margin-top: 20px;
}
.newsletter {
  max-width: 1184px !important;
  margin: auto;
}
.newsletter h5, .newsletter h3 {
  color: white;
  font-weight: bold;
}
.newsletter h5 {
  font-size: 20px;
}
.newsletter h3 {
  font-size: 30px;
}
.newsletter span {
  font-size: 12px;
  color: white;
}
.newsletter button{
  background-color: #C74D06;
  color: white;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100px;
  height: 41px;
}

.newsletter input{
  width: 300px;
  height: 35px;
}

#pagamento_image {
  background: url('/images/footer/metodi_pagamento.png');
}
#gls_image {
  background: url('/images/footer/gls.png');
}
#tnt_image {
  background: url('/images/footer/tnt.png');
}

/* CSS for Mobile */
@media only screen and (max-width: 600px) {
  body {
    width: 101%;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .wf-materialicons-n4-active.wf-roboto-n5-active.wf-roboto-n7-active.wf-roboto-n3-active.wf-active {
    width: 101% !important;
    margin-left: -8px !important;
  }

  .container {
    width: 77% ;
    margin:auto;
    padding-left: 0;
    padding-right: 0;
  }

  #desktop, #desktop_add_cart, .NavBarMini-navBarMini-0-1-2, #menu_desktop {
    display: none;
  }

  #mobile, #mobile_add_cart, #prezzo_mobile {
    display: block;
  }

  .container-fluid {
    padding: 0 !important;
  }

  .App-body-0-1-1.container-fluid > * {

  }

  #mobile_add_cart {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-left: -16px;
    margin-right: -8px;
    margin-bottom: -12px;
  }

  .row {
    margin: 0px !important;
  }

  /*.mdc-layout-grid__inner {
    display: block;
  }*/
  .mdc-layout-grid__cell--span-8 {
    grid-column-end: spa !important;
  }

  img.carrelloMobile {
    display: inline-block;
    float: left;
    height: 78px !important;
    max-height: 78px !important;
  }

  .category-home {
    background-size: cover !important;
    width: 100%;
    heigth: 100%;
    padding: 0 !important;
  }

  .Home-homeCategories-0-1-66 > div > div {
    width: 100% !important;
  }
  .col-xs-12 {
    -webkit-flex-basis: 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  /*.col-xs-3 {
    z-index:10;
  }*/

  .newsletter {
    margin: auto;
    padding-top:10px;
    padding-left:15px;
    padding-right:15px;
  }

  .newletterGrid, .newsletter h5, .newsletter h3 {
    margin:auto;
    padding-top:10px;
    padding-left:15px;
    padding-right:15px;
  }

  #react-paginate ul {
    margin: auto
  }

}
