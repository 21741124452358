html,
body {
    margin: 0;
    outline: none;
}

#App {
    font-family: sans-serif;
    /* Give app full page to work with */
    /*height: 100vh;*/
}

#page-wrap {
    text-align: center;
    /* Prevent sidebar from showing a scrollbar on page */
    overflow: auto;
}

/* Individual item */
.bm-item {
    display: inline-block;
    margin-left: -40px;
    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 20px;
    color: #d1d1d1;
    outline: none;
    /*transition: color 0.2s;*/
}

/* Change color on hover */
.bm-item:hover {
    color: white;
    outline: none;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 25px;
    left: 10px;
    top: 16px;
    outline: none;
    z-index:0 !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
    outline:none;
    height: 10% !important;
    border: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    outline: none;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
    outline: none;
}

/* General sidebar styles*/
.bm-menu {
    background: #fff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    outline: none;
    width: 298px;
}

ul.bm-item{
    width: 301px;
    outline: none;
}

#prova{
    width: 400px;
    outline: none;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #fff;
    outline: none;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    outline: none;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    outline: none;
}

a{
    color: #778899;
    outline: none;
}

a:active {
    color: cornflowerblue;
    outline: none;
}

ul{
    margin-left: -30px;
    max-width: 350px;
    padding: 16px;
    padding-left: 30px;
    outline: none;
}

ul.bm-item{
    width: 255px;
    outline: none;
}

div.bm-menu-wrap.menu{
    outline: none;
}

.Collapsible {
    display: initial;
    margin-top: 10px !important;
    height: 30px !important;
    vertical-align: -webkit-baseline-middle;
    outline: none;
}

hr{
    margin:0 0 18px 0 !important;
}

